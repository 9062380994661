body {
  font-family: "Noto Sans", sans-serif;
}

.font-rubik {
  font-family: "Rubik", sans-serif;
}

.word-break {
  word-break: break-word;
}

.bg-lightest {
  background-color: #f2f6fa;
}

.hover\:bg-lightest:hover {
  background-color: #f2f6fa;
}

.bg-lighter {
  background-color: #f0f3f7;
}

.hover\:bg-lighter:hover {
  background-color: #f0f3f7;
}

.bg-tune {
  background-color: #f0f7ff;
}

.hover\:bg-tune:hover {
  background-color: #f0f7ff;
}

.focus\:bg-tune:focus {
  background-color: #f0f7ff;
}

.bg-light-blue {
  background-color: #e8f3ff;
}

.border-light-blue {
  border-color: #e8f3ff;
}

.focus\:bg-light-blue:focus {
  background-color: #e8f3ff;
}

.hover\:bg-light-blue:hover {
  background-color: #e8f3ff;
}

.bg-dark-blue {
  background-color: #4061bb;
}

.bg-green {
  background-color: #31d7b6;
}

.bg-lines {
  background-color: #d3ddec;
}

.border-lines {
  border-color: #d3ddec;
}

.bg-dark-blue {
  background-color: #4061bb;
}

.bg-bright-blue {
  background-color: #5c92ff;
}

.text-bright-blue {
  color: #335bfb;
}

.border-bright-blue {
  border-color: #335bfb;
}

.text-lightest {
  color: #bfcde8;
}

.text-darkest {
  color: #435070;
}

.border-darkest {
  border-color: #435070;
}

.text-midgray {
  color: #8598bd;
}

.text-mild-blue {
  color: #6380d0;
}

.bg-mild-blue {
  background-color: #6380d0;
}

.border-mild-blue {
  border-color: #6380d0;
}

.bg-light-sky {
  background-color: #ebf4ff;
}

.text-dark-sky {
  color: #84a8de;
}

.pane-shadow {
  box-shadow: 0px 2px 16px rgba(10, 29, 81, 0.03),
    inset 0px 0px 1px rgba(255, 255, 255, 0.3);
}

/** New colors **/
.bg-nectar {
  background-color: #385898;
}

.bg-atomic {
  background-color: #fafcff;
}

.bg-cold {
  background-color: #f1f4fa;
}

.bg-light-orange {
  background-color: #ffefe0;
}

.text-dark-orange {
  color: #eba68d;
}

.bg-light-red {
  background-color: #ffebe8;
}

.text-dark-red {
  color: #f9a099;
}

.hover\:bg-creed:hover {
  background-color: #ebeff7;
}

.focus\:bg-bower:focus {
  background-color: #e6effc;
}

.text-moist {
  color: #13316c;
}

.text-centaur {
  color: #7489b3;
}

.border-paper {
  border-color: #e4e9f2;
}

.border-moist {
  border-color: #13316c;
}

.grade-line {
  background: linear-gradient(90.15deg, #31d7b6 0%, #305195 100%);
}

td {
  transition: background-color 0.2s;
}

tr:hover td {
  background-color: #fafcff !important;
}
